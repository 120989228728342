// reducers/confirmationReducers.js
const initialState = {
  isOpen: false,
  title: "",
  content: "",
  onConfirm: null, // Function to execute on confirmation
  cancelLabel: "Cancel", // Default cancel button label
  confirmLabel: "Confirm", // Default confirm button label
}

export const confirmationPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_CONFIRMATION_POPUP":
      return {
        ...state,
        isOpen: true,
        title: action.payload.title,
        content: action.payload.content,
        onConfirm: action.payload.onConfirm,
        cancelLabel: action.payload.cancelLabel,
        confirmLabel: action.payload.confirmLabel,
      }
    case "HIDE_CONFIRMATION_POPUP":
      return initialState
    default:
      return state
  }
}
