// actions/confirmationActions.js
export const showConfirmationPopup = (
  title,
  content,
  onConfirm,
  cancelLabel = "Cancel",
  confirmLabel = "Confirm"
) => ({
  type: "SHOW_CONFIRMATION_POPUP",
  payload: { title, content, onConfirm, cancelLabel, confirmLabel },
})

export const hideConfirmationPopup = () => ({
  type: "HIDE_CONFIRMATION_POPUP",
})
